<template>
  <div class="seso">
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>SESO</span>
    </div>
    <iframe width="100%" allowfullscreen  style="height: calc(100vh - 60px);border: none;z-index: 999;" :src="userinfo.seso_url"></iframe>
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
export default {
  data() {
    return {
      userinfo: {},
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    getUserInfo() {
      homeApi.getUserInfo({}).then(res => {
        if (res.code == 200) {
          this.userinfo = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.seso {
  min-height: 100vh;
  padding-top: 50px;
  .head {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(#000, 0.8);
    letter-spacing: 1.5px;
    font-weight: 550;
    background: #fff;
    padding: 14px 0;
    .arrow_img {
      position: absolute;
      left: 20px;
      width: 10px;
      height: 16px;
    }
  }
}
</style>