import Vue from 'vue';
import Vuex from 'vuex';
import Web3 from 'web3';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
//模块化store
// import Sys from '../components/modal/store';
Vue.use(Vuex);
import { usApi, homeApi } from '../api/index';
const { ethers, utils, Wallet } = require("@/utils/ethers.min.js");
import { Notify } from 'vant';
import axios from "axios";
export default new Vuex.Store({
  state: {
    provider: {},
    account: '',
    net: 0,
    gasPrice: 5000000000,
    slippage: 5,
    deadline: 20,
    multipath: 0,
    transactions: {},
    block: 0,
    expert: false,
  },
  mutations: {
    SETPROVIDER: (state, provider) => {
      state.provider = provider;
    },
    SETBLOCK: (state, block) => {
      state.block = block;
    },
    SETEXPORT: (state, provider) => {
      state.expert = provider
    },

    DELETETRANSACTIONS: state => {
      state.transactions = {};
    },
    SETTRANSACTIONS: (state, transactionsDetail) => {
      let tempObj = JSON.parse(JSON.stringify(state.transactions));
      tempObj[transactionsDetail.hash] = transactionsDetail;
      state.transactions = tempObj;
    },
    SETTRANSACTIONSRECEIPT: (state, transactionsDetail) => {
      let tempObj = JSON.parse(JSON.stringify(state.transactions));
      tempObj[transactionsDetail.hash].receipt = transactionsDetail.receipt;
      state.transactions = tempObj;
    },
    SETTOKEN: (state, token) => {
      state.token = token;
    },

    SETACCOUNTS: (state, account) => {
      state.account = account;
      if (account) {
        // const domain = {
        //   name: 'Butterfly',
        //   version: 'v1.0',
        //   chainId: '56',
        //   verifyingContract: '',
        // };

        // const EIP712Domain = [
        //   { name: 'name', type: 'string' },
        //   { name: 'version', type: 'string' },
        //   { name: 'chainId', type: 'uint256' },
        // ];

        // const Primary = [
        //   { name: 'from', type: 'address' },
        //   { name: 'content', type: 'string' },
        // ];

        // const message = {
        //   from: account,
        //   content: 'Register User',
        // };

        // const data = JSON.stringify({
        //   types: {
        //     EIP712Domain,
        //     Primary,
        //   },
        //   domain,
        //   primaryType: 'Primary',
        //   message,
        // });
        // console.log(localStorage.getItem("token"), '获取到的token');
        account = account.toLowerCase()

        console.log(localStorage.getItem("account"), account, "-----------------------111");
        if (account != localStorage.getItem("account") || !localStorage.getItem("token__" + account)) {
          let web3 = new Web3(window.web3.currentProvider);
          web3.eth.personal.sign(web3.utils.fromUtf8('register'), account, (err, res) => {
            if (res) {
              let data = {
                address: account,
                sign: res,
                // inviteCode: localStorage.getItem("inviteCode")
              };
              homeApi.auth(data).then(res => {
                if (res.code == 200) {
                  localStorage.setItem("account", account)
                  localStorage.setItem("userinfo", JSON.stringify(res.data))
                  localStorage.setItem("token__" + account, res.data.token)
                  localStorage.setItem("parentId", res.data.parent_id)
                  localStorage.setItem("nologin", false)
                  Vue.prototype.$bus.$emit('updateUserinfo', 'update')
                } else {
                }
              })
            }else{
              Notify({ type: 'danger', message: "请使用冷钱包进行扫码签名" });
              localStorage.setItem("nologin", true)
              return;
            }
          }).catch(err=>{
            // Notify({ type: 'danger', message: ercode });
            if(err.code == 4001){
              Notify({ type: 'danger', message: "请验签" });
            }
          })
        } else {
          Vue.prototype.$bus.$emit('updateUserinfo', 'update')
        }
        // else {
        //   console.log(222);
        //   let web3 = new Web3(window.web3.currentProvider);
        //   web3.eth.personal.sign(web3.utils.fromUtf8('register'), account, (err, res) => {
        //     console.log(res, "我是store里面的");
        //     let data = {
        //       address: account,
        //       sign: res,
        //     };
        //     homeApi.auth(data).then(res => {
        //       if (res.code == 200) {
        //         localStorage.setItem("userinfo", JSON.stringify(res.data))
        //         localStorage.setItem("token", res.data.token)
        //         localStorage.setItem("parentId", res.data.parent_id)
        //       } else {
        //         localStorage.removeItem("userinfo")
        //         localStorage.removeItem("token")
        //         localStorage.removeItem("parentId")
        //       }
        //     })
        //   });
        // }


      }
    },
    SETNET: (state, net) => {
      state.net = net;
    },
    SET_GASPRICE(state, gasPrice) {
      state.gasPrice = gasPrice;
    },
    SET_SLIPPAGE(state, slippage) {
      state.slippage = slippage;
    },

    SET_POOL: (state, pool) => {
      state.pool = pool
    },

    SET_DEADLINE(state, deadline) {
      state.deadline = deadline;
    },
    SET_MULTIPATH(state, multipath) {
      state.multipath = multipath;
    },

    SET_EXPORT(state, ex) {
      state.expert = ex
    }
  },
  actions: {
    async setWallet() {
      var web3Provider;
      if (window.web3) {
        web3Provider = window.web3;
        try {
          await web3Provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x38',
                chainName: 'Binance Smart Chain Mainnet',
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'bnb',
                  decimals: 18,
                },
                rpcUrls: ['https://bsc-dataseed4.defibit.io/'],
                blockExplorerUrls: [`https://bscscan.com/`],
              },
            ],
          });
          await web3Provider.enable();
        } catch (error) {
          console.error('User denied account access');
        }
      }
    },
    async setWebProvider({ commit }) {
      var web3Provider;
      if (window.ethereum) {
        web3Provider = window.ethereum;
        console.log(web3Provider.chainId);
        if (web3Provider.chainId != '0x38') {
          await web3Provider.request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: '0x38'
              },
            ],
          });
          await web3Provider.enable();
        } else {
          try {
            // 请求用户授权
            await web3Provider.request({
              method: 'eth_requestAccounts',
              params: [
                {
                  chainId: '0x38',
                  chainName: 'Binance Smart Chain Mainnet',
                  nativeCurrency: {
                    name: 'BNB',
                    symbol: 'bnb',
                    decimals: 18,
                  },
                  rpcUrls: ['https://bsc-dataseed4.defibit.io/'],
                  blockExplorerUrls: [`https://bscscan.com/`],
                },
              ],
            });
            await web3Provider.enable();
          } catch (error) {
            console.error('User denied account access');
          }
        }

        const web3js = new Web3(web3Provider);
        commit('SETPROVIDER', web3js);
        web3js.eth.net.getId(function (error, result) {
          if (!error) {
            console.log(result, '网络');
            commit('SETNET', result);
          }
        });
        web3js.eth.getAccounts(function (error, result) {
          if (!error) {
            console.log(result, '账号');
            commit('SETACCOUNTS', result[0]);
          }
        });
        web3Provider.on('networkChanged', function (networkIDstring) {
          commit('SETNET', networkIDstring);
        });
        web3Provider.on('accountsChanged', function (accounts) {

          localStorage.clear()
          //切换账号一定会走这  清空token 请求auth 拿到新的token  走事件总线 emit 触发首页加载个人信息的方法  跳转回首页
          commit('SETACCOUNTS', accounts[0]);

          console.log('切换账号', accounts);
        });
      }
    },
  },
  plugins: [
    createPersistedState({
      paths: ['gasPrice', 'slippage', 'deadline', 'multipath', 'transactions', 'block', "pool", "expert"],
    }),
  ],
});
