<template>
  <div class="trade">
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>社区长团队统计</span>
    </div>
    <div class="trade_top">
      <div class="team_box">
        <div class="team_universal" style="margin-bottom: 10px">
          <span>服务器算力</span>
          <span class="universal_amount" style="font-size: 20px">{{ dataObj.server_power || 0 }}</span>
        </div>
        <div class="team_list">
          <div class="team_universal">
            <span>社区长</span>
            <span class="universal_amount">{{ dataObj.community_count || 0 }}</span>
          </div>
          <div class="team_universal">
            <span>系统领导人</span>
            <span class="universal_amount">{{ dataObj.sys_leader_count || 0 }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="gain">
      <div class="gain_title">
        <span @click="gainClick(1)" :class="gainIndex == 1 ? 'special' : ''">社区长列表</span>
        <span @click="gainClick(2)" :class="gainIndex == 2 ? 'special' : ''">服务器购买列表</span>
      </div>

      <div>
        <div class="list_title">
          <span>地址</span>
          <span style="text-align: center" v-if="gainIndex == 1">等级</span>
          <span style="text-align: right" v-if="gainIndex == 1">算力</span>
          <span style="text-align: center" v-if="gainIndex == 2">算力</span>
          <span style="text-align: right" v-if="gainIndex == 2">购买时间</span>
        </div>
        <!-- 社区长列表 -->
        <div v-show="gainIndex == 1">
          <div class="list" v-if="dataObj.community_list.length > 0">
            <div v-for="(item, index) in dataObj.community_list" :key="index" class="li">
              <span>{{ item.address }}</span>
              <span style="text-align: center">{{ item.level }}</span>
              <span style="text-align: right">{{ item.power || 0 }}</span>
            </div>
          </div>
          <div class="bare" v-else>
            <span>暂无数据</span>
          </div>
        </div>
        <!-- 服务器购买列表 -->
        <div v-show="gainIndex == 2">
          <div class="list" v-if="dataObj.servers_list.length > 0">
            <div v-for="(item, index) in dataObj.servers_list" :key="index" class="li">
              <span>{{ item.address }}</span>
              <span style="text-align: center">{{ item.power || 0 }}</span>
              <span style="text-align: right">{{ dateFormatFn(item.create_time) }}</span>
            </div>
          </div>
          <div class="bare" v-else>
            <span>暂无数据</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapState } from 'vuex';
import Web3 from 'web3';
import Clipboard from 'clipboard';
import { homeApi } from '@/api/index';
import { dateFormat, typemat, addressFormat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      gainIndex: 1,
      dataObj: {
        server_power: undefined,
        team_num: undefined,
        direct_num: undefined,
        community_list: [],
        servers_list: [],
      },
    };
  },
  mounted() {
    this.serviceTeam();
  },
  methods: {
    addressFormat,
    //返回上一页
    back() {
      this.$router.back();
    },
    serviceTeam() {
      homeApi.serviceTeam().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.dataObj = res.data;
        }
      });
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },
    typeFormatFn(type) {
      return typemat(type);
    },
    // addressFormatFn(address) {
    //   return addressFormat(address);
    // },
    gainClick(i) {
      this.gainIndex = i;
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.serviceTeam();
    },
  },
  components: {},
};
</script>
  
  <style lang="less" scoped>
.trade {
  .head {
    position: relative;
    display: flex;
    justify-content: center;
    color: #000;
    background: #fff;
    padding: 20px 0 10px;
    .arrow_img {
      position: absolute;
      left: 20px;
      top: 46%;
      width: 10px;
      height: 16px;
      // transform: translate(0, -50%);
    }
  }
  .trade_top {
    background: #feffff url('~@/assets/image/mining_bg.png') no-repeat;
    background-size: 100%;
    padding: 100px 20px 20px;
  }
  .team_box {
    position: relative;
    background: #fff;
    box-shadow: 0 0 4px rgba(77, 146, 223, 0.16);
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 20px;
    .team_universal {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #999;
      .universal_amount {
        font-size: 16px;
        font-weight: bold;
        color: #000;
        margin-top: 6px;
      }
    }
    .team_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .yesterday {
    display: flex;
    align-items: center;
    color: #a5a5a5;
    .yesterday_img {
      display: block;
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
  .earnings {
    display: flex;
    align-items: center;
    padding: 20px 0;
    .earnings_li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 32%;
      font-size: 14px;
      .li_amount {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .gain {
    background: #fff;
    margin-top: 10px;
    padding-bottom: 20px;
    .gain_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
      padding: 20px 20px;
      span {
        border-left: 3px solid #238ce800;
        padding-left: 6px;
      }
      .special {
        color: #238ce8;
        border-left: 3px solid #238ce8;
      }
    }
    .list_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #666666;
      padding: 0 30px;
      margin-bottom: 10px;
      span {
        width: 30%;
      }
    }
    .li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #666666;
      padding: 0 30px;
      margin-top: 10px;
      span {
        width: 30%;
      }
    }
    .bare {
      text-align: center;
      font-size: 14px;
      padding: 14px;
      color: #888;
    }
  }
}
</style>