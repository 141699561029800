<template>
  <div class="invite">
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>订单</span>
    </div>

    <!-- <div class="flex-row">
      <van-popover v-model="showPopover1" :border="false" trigger="click" :actions="orderTypeList" @select="onSelect1" id="popover1">
        <template #reference>
          <div class="picker">
            <span class="text">{{ orderTypeText || '全部' }}</span>
            <img class="down" referrerpolicy="no-referrer" src="@/assets/images/order/down.png" />
          </div>
        </template>
      </van-popover>
      <van-popover v-model="showPopover2" :border="false" trigger="click" :actions="orderStatusList" @select="onSelect2" id="popover2">
        <template #reference>
          <div class="picker">
            <span class="text">{{ orderStatusText || '全部' }}</span>
            <img class="down" referrerpolicy="no-referrer" src="@/assets/images/order/down.png" />
          </div>
        </template>
      </van-popover>
    </div> -->
    <template v-if="list.length > 0">
      <div class="today_reserve" v-for="(item, index) in list" :key="index">
        <div class="top flex">
          <span class="top_text1">{{ item.pro_name }}</span>
          <span class="top_text2">{{ dateFormatFn(item.create_time) }}</span>
        </div>
        <div class="box_4"></div>

        <div class="flex">
          <div>
            <div>
              <span>价格</span>
              <span class="text3">{{ Math.floor(item.amount * 100) / 100 }}U</span>
            </div>
            <div style="padding-top: 10px">
              <span>算力</span>
              <span class="text3">{{ Math.floor(item.power * 100) / 100 }} T</span>
            </div>
          </div>
          <div class="flex">
            <div @click="payOrder(item)" v-if="item.status === 0">
              <div class="status status4">
                <span>
                  {{ item.status_name || '其它' }}
                </span>
              </div>
            </div>
            <div v-else>
              <div class="status status1">
                <span>
                  {{ item.status_name || '其它' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="nodata item">
        <span> 暂无数据 </span>
      </div>
    </template>
    <!-- 加载弹窗 -->
    <van-popup v-model="isApprove" round style="width: 60%" :close-on-click-overlay="false">
      <div class="bind_box">
        <div style="font-weight: bold"><van-loading type="spinner" /></div>
        <div class="address_field">处理中...</div>
      </div>
    </van-popup>
    <!-- 卖出或租赁弹框 -->
    <!-- <van-popup v-model="soldOrLeaseShow" round style="width: 80%">
      <div class="soldOrLeasePopup">
        <div class="title">
          <span>操作</span>
        </div>
        <div>
          <div class="flex">
            <div class="btn" @click="soldOrLease(1)">
              <span>我要出售</span>
            </div>
            <div class="btn" @click="soldOrLease(2)">
              <span>算力租赁</span>
            </div>
          </div>
          <div class="close—icon" @click="soldOrLeaseShow = false">
            <van-icon name="close" size="24" color="#C8C9CC" />
          </div>
        </div>
      </div>
    </van-popup> -->
  </div>
</template>
    
<script>
import { mapState } from 'vuex';
import { Toast } from 'vant';
import usdt from '@/methods/usdt.js';
import { homeApi } from '@/api/index';
import { dateFormat } from '@/utils/formatTool.js';
import packApi from '@/methods/pack.js';
import { getErc20Contract } from '@/utils/contractHelp';
import { formatAmount } from '@/utils/format.js';
export default {
  data() {
    return {
      showPopover1: false,
      showPopover2: false,

      list: [],
      page: 0,
      loading: true,
      finished: false,
      total: 1,
      typeId: -1,
      statusId: -1,

      orderTypeTotal: 0,
      orderStatusTotal: 0,
      indexinfo: {},
      typeFormat: [],
      payStatusFormat: [],
      orderStatusFormat: [],
      finishText: '加载更多',
      isApprove: false,
      payToken: undefined, //支付币种
      walletBalance: 0, // 钱包余额
      soldOrLeaseShow: false, //提现弹窗状态
      temporaryOrderSn: '', //存储售出或租赁操作的订单号
      payment_end: 0, //支付截止时间
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.orderList();
      this.getPayToken(); //获取支付币种
    },
    getPayToken() {
      packApi.getPayToken(this.account).then(res => {
        this.payToken = res;
        // this.handleApproveBtnShow(); //获取授权余额
        this.getWalletBalance(); //获取钱包余额
      });
    },
    // 查询链上钱包余额
    async getWalletBalance() {
      const erc20Contract = getErc20Contract(this.payToken);
      let bal = await erc20Contract.methods.balanceOf(this.account).call();
      this.walletBalance = (formatAmount(bal, 18) * 1).toFixed(2);
    },
    //返回上一页
    back() {
      this.$router.back();
    },

    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },

    payOrder(item) {
      this.isApprove = true;
      let params = { order_no: item.order_no, order_type: item.order_type };
      homeApi
        .getOrder(params)
        .then(res => {
          if (res.code == 200) {
            if (res.data.amount > this.walletBalance * 1) {
              this.$toast('余额不足');
              this.isApprove = false;
              return;
            } else {
              this.payMethod(res.data, item.order_type);
            }
          } else {
            this.isApprove = false;
            this.$toast(err.msg);
            return;
          }
        })
        .catch(err => {
          this.isApprove = false;
          this.$toast(err.msg);
          return;
        });
    },
    payMethod(obj, order_type) {
      usdt
        .transfer(this.account, obj.pay_address, obj.amount, this.payToken)
        .then(res => {
          console.log(res);
          // 调回调接口
          let params = {
            amount: obj.amount,
            type: order_type,
            order_no: obj.order_no,
            tx_hash: res,
          };
          homeApi
            .updateOrder(params)
            .then(res => {
              this.isApprove = false;
              if (res.code == 200) {
                this.init();
                Toast({ message: `${res.msg}` });
              }
            })
            .catch(err => {
              this.$toast(err.msg);
              this.isApprove = false;
              return;
            });
        })
        .catch(err => {
          console.log(' 5555');
          this.$toast(err.message);
          this.isApprove = false;
          return;
        });
    },
    orderList() {
      let params = {};
      homeApi
        .serversList(params)
        .then(res => {
          console.log(res, 'list');
          if (res.code == 200) {
            this.list = res.data;
          } else {
          }
        })
        .catch(err => {});
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>
<style lang="less" scoped>
.invite {
  min-height: calc(100vh + 50px);
  padding-top: 50px;
  position: relative;
  .head {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(#000, 0.8);
    letter-spacing: 1.5px;
    font-weight: 550;
    padding: 14px 0;
    top: 0;
    width: 100%;
    background: #fff;
    .arrow_img {
      position: absolute;
      left: 20px;
      width: 10px;
      height: 16px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex-row {
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
  .picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    width: 115px;
    height: 40px;
    margin: 16px 16px 0;
    padding: 16px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 20px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    .text {
      color: rgba(51, 51, 51, 1);
      font-size: 13px;
      font-weight: 500;
    }
    .down {
      width: 12px;
      height: 6px;
    }
  }
  .box_4 {
    background-color: rgba(236, 242, 245, 1);
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    margin: 10px 0 6px;
  }
  .today_reserve {
    color: rgba(153, 153, 153, 1);
    font-size: 14px;
    padding: 15px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    border-radius: 15px;
    margin: 16px;
    background-color: rgba(255, 255, 255, 0.3);
    .top {
      // padding-bottom: 20px;
      &_text1 {
        font-size: 15px;
        color: rgba(51, 51, 51, 1);
        // font-weight: 550;
      }
      &_text2 {
        font-size: 12px;
        color: rgba(153, 153, 153, 1);
      }
    }
    .text3 {
      color: rgba(#000, 1);
      padding-left: 6px;
    }
    .right {
      // width: calc(100% - 64px);
      // margin-left: 16px;
    }
    .type {
      height: 45px;
      width: 45px;
      line-height: 45px;
      text-align: center;
      font-size: 12px;
      border-radius: 50%;
    }
    .type1 {
      color: rgba(20, 128, 255, 1);
      border: 1px solid rgba(20, 128, 255, 0.2);
      background-color: rgba(20, 128, 255, 0.15);
    }
    .type2 {
      color: rgba(255, 31, 106, 1);
      border: 1px solid rgba(255, 31, 106, 0.2);
      background-color: rgba(255, 31, 106, 0.15);
    }
    .type3 {
      color: rgba(16, 195, 138, 1);
      border: 1px solid rgba(16, 195, 138, 0.2);
      background-color: rgba(16, 195, 138, 0.15);
    }
    .type4 {
      color: rgba(122, 153, 153, 1);
      border: 1px solid rgba(122, 153, 153, 0.3);
      background-color: rgba(224, 240, 240, 0.6);
    }
    .status1 {
      font-size: 12px;
      color: rgba(16, 209, 188, 1);
      padding: 6px 16px;
      background-color: rgba(224, 242, 240, 1);
      border-radius: 30px;
    }
    .status2 {
      font-size: 12px;
      color: rgba(229, 92, 41, 1);
      padding: 6px 16px;
      background-color: rgba(250, 234, 228, 1);
      border-radius: 30px;
      display: flex;
      align-items: center;
      .countdownstatus2 {
        font-size: 12px;
        color: rgba(229, 92, 41, 1);
      }
    }
    .status3 {
      font-size: 12px;
      color: rgba(122, 153, 153, 1);
      padding: 6px 16px;
      border-radius: 30px;
      background-color: rgba(224, 240, 240, 1);
    }
    .status4 {
      color: rgba(216, 176, 24, 1);
      font-size: 12px;
      padding: 6px 16px;
      background-color: rgba(247, 243, 228, 1);
      border-radius: 30px;
    }
    .status5 {
      color: rgba(65, 89, 213, 1);
      font-size: 12px;
      padding: 6px 16px;
      background-color: rgba(228, 234, 247, 1);
      border-radius: 30px;
    }
  }
  .padd {
    padding: 10px 0 8px;
  }

  .soldOrLeasePopup {
    padding: 20px 30px;
    color: #666666;
    font-size: 14px;
    .title {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      font-weight: bold;
      font-size: 16px;
      color: #000000;
    }

    .tip {
      font-size: 12px;
      display: flex;
      flex-direction: column;
    }
    .btn {
      color: #fff;
      padding: 16px 22px;
      text-align: center;
      line-height: 50%;
      border-radius: 20px;
      margin-top: 18px;
      background-color: #238be8;
    }
    .close—icon {
      position: absolute;
      bottom: -40px;
      left: 45%;
    }
  }
}
.nodata {
  color: rgba(#000, 0.6);
  text-align: center;
  line-height: 380%;
}
// /deep/ .van-list__finished-text{
//   padding-top: 60px;
// }
.bind_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  border-radius: 16px;
  background-color: #000;
  .address_field {
    padding-top: 16px;
  }
}
/deep/ .van-popup {
  overflow-y: visible;
}
</style>