<template>
  <div>
    <!-- <div class="head">
        <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
        <span>资产明细</span>
        <span class="right_text" @click="toRecord">购买记录</span>
      </div> -->
    <div class="assets">
      <div class="total_box">
        <span>服务器算力总额</span>
        <span class="total">{{ Math.floor(totalPower * 100) / 100 || 0 }}</span>
      </div>
      <div class="caption">
        <span>服务器列表</span>
        <span class="right_arrow" @click="toRecord">购买记录</span>
      </div>

      <div v-if="list.length > 0">
        <div class="trade-list">
          <div class="trade-item" v-for="(item, index) in list" :key="index">
            <div class="trade-card">
              <img src="@/assets/image/introduce_img.png" class="trade-icon" mode="aspectFit" />
              <div class="trade-card-info">
                <div class="card-bigTitle">
                  {{ item.title }}
                </div>
                <div style="padding: 12px 0">
                  <span class="card-info-key">价格</span>
                  <span class="card-info-val card-info-val_highlight">{{ item.price }} USDT</span>
                </div>
                <div>
                  <span class="card-info-key">算力</span>
                  <span class="card-info-val">{{ item.power }}</span>
                </div>
              </div>
              <div class="trade-buy" @click="buy(item)">购买</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bare" v-else>
        <span>暂无数据</span>
      </div>
      <van-overlay :show="show">
        <div class="wrapper" @click.stop>
          <van-loading type="spinner" class="loading" />
        </div>
      </van-overlay>
    </div>
  </div>
</template>
  
  <script>
import { homeApi } from '@/api/index';
import packApi from '@/methods/pack.js';
import { Notify } from 'vant';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import { accMul } from '@/utils/format.js'; //有空看看这里 这个地方封装了bignumer处理 好
import { mapState } from 'vuex';
export default {
  data() {
    return {
      page: 0,
      loading: false,
      finished: false,
      total: 1,
      list: [],
      totalPower: 0,
      actions: [],
      showPopover: false,
      typeText: '全部',
      contract: '0x7534f6771A9e470212ad5fA4c1f5f3a9D3Edcb07', //合约地址
      payToken: undefined, //支付币种
      actionType: -1, //-1:全部 0:未知 1:租赁 2:直推奖励 3:团队的算力奖励 4:团队的平级算力奖励
      show: false,
      showApprove: false, //授权
      isApprove: false, //是否有授权
    };
  },
  computed: {
    ...mapState(['account']),
  },
  mounted() {
    this.getPayToken(); //获取支付币种
    this.serversInfo();
  },
  methods: {
    serversInfo() {
      this.loading = true;
      this.finished = false;
      homeApi.serversInfo({}).then(res => {
        if (res.code == 200) {
          this.list = res.data.info;
          this.totalPower = res.data.server_power;
        }
      });
    },
    getPayToken() {
      packApi.getPayToken(this.account).then(res => {
        this.payToken = res;
        this.handleApproveBtnShow(); //获取授权余额
      });
    },
    async handleApproveBtnShow() {
      console.log('走了这里' + this.payToken);
      const erc20ContractV2 = getErc20Contract(this.payToken);
      const toV2 = this.contract;
      const allowanceV2 = await erc20ContractV2.methods.allowance(this.account, toV2).call();
      console.log(allowanceV2, '授权所剩余额');
      if (allowanceV2 == 0) {
        this.showApprove = true;
      } else {
        this.showApprove = false;
      }
    },
    async buy(item) {
      // 授权
      this.show = true;
      const erc20Contract = useErc20Contract(this.payToken);
      const amo = MaxUint256.toString();
      this.isApprove = true;
      await erc20Contract.methods
        .approve(this.contract, amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {})
        .on('error', (error, receipt) => {
          this.isApprove = false;
          this.show = false;
          this.$toast(this.$t('lang.swap599'));
        });
      this.submit(item);
    },
    submit(item) {
      //注意  转账必须先走授权
      // this.isApprove = true;
      packApi
        .pledge(this.account, accMul(item.price, Math.pow(10, 18)))
        .then(res => {
          let data = {
            id: item.id,
            fee: item.price,
            txHash: res,
          };
          //开始走逻辑，发送接口给yoki
          homeApi
            .serversRental(data)
            .then(res => {
              this.isApprove = false;
              if (res.code == 200) {
                this.show = false;
                Notify({ type: 'primary', message: '操作成功' });
              } else {
                Notify({ type: 'danger', message: res.msg });
              }
            })
            .catch(err => {
              this.isApprove = false;
              this.show = false;
            });
        })
        .catch(err => {
        });
    },
    toRecord(){
      this.$router.push({ name: 'buyRecord' });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.head {
  position: relative;
  display: flex;
  justify-content: center;
  color: #000;
  background: #fff;
  padding: 20px 0 10px;
  .arrow_img {
    position: absolute;
    left: 20px;
    top: 46%;
    width: 10px;
    height: 16px;
    // transform: translate(0, -50%);
  }
  .right_text {
    position: absolute;
    right: 20px;
  }
}
.assets {
  padding: 20px;
  .total_box {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #fff;
    background: #238be8;
    border-radius: 12px;
    padding: 20px;
    .total {
      font-size: 26px;
      font-weight: bold;
      margin-top: 14px;
    }
  }
  .caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #238be8;
    font-size: 14px;
    font-weight: bold;
    border-left: 4px solid #238be8;
    padding-left: 6px;
    margin: 20px 0 10px;
    .right_arrow {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0;
    .li_left {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: rgba(51, 51, 51, 1);
      .time {
        color: rgba(102, 102, 102, 1);
        margin-top: 4px;
      }
    }
    .li_right {
      font-size: 16px;
      color: #000;
    }
  }
  .bare {
    text-align: center;
    font-size: 14px;
    padding: 60px 14px;
    color: #888;
  }
}
.trade-list {
  padding: 20px 0 0;

  .trade-item {
    padding: 16px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 0 0.25rem rgba(77, 146, 223, 0.16);
    margin-bottom: 12px;
    // box-shadow: inset 0px 0px 12px rgba(249, 67, 255, 0.3);

    .trade-buy {
      background: #238be8;
      border-radius: 4px;
      width: 60px;
      height: 30px;
      color: #fff;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      padding: 0px 6rpx;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .grey {
      background: #29303d;
      color: #78869e;
    }

    .trade-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //   padding-bottom: 16px;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.05);

      .trade-icon {
        width: 64px;
        height: 64px;
      }

      .trade-card-info {
        line-height: 18px;
        padding: 0 12px;
        flex: 1;

        .card-bigTitle {
          font-weight: 500;
          font-size: 16px;
          //   color: #fff;
        }

        .card-info-key {
          color: #999;
          margin-right: 5px;
        }
        .card-info-val {
          color: #999;
        }
        .card-info-val_del {
          text-decoration: line-through;
          margin-right: 6px;
        }
        .card-info-val_highlight {
          color: #999;
          margin-right: 6px;
        }
        .card-info-key,
        .card-info-val {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .trade-info {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;

      .trade-info-item {
        width: 33.333333%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .trade-info-key {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #999;
        }
        .trade-info-val {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #000;
        }
      }
    }
  }
}
.wrapper {
  // display: flex;
  // justify-content: center;
  .loading {
    position: absolute;
    left: 46%;
    top: 46%;
  }
}
</style>