<template>
  <div>
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>购买记录</span>
    </div>
    <div class="assets">
      <div v-if="list.length > 0">
        <!-- <div v-for="(item, index) in list" :key="index" class="li">
          <div class="li_left">
            <span>{{ item.note }}</span>
            <span class="time">{{ dateFormatFn(item.create_time) }}</span>
          </div>
          <span class="li_right">{{ Math.floor(item.amount * 100) / 100 || 0 }}</span>
        </div> -->
        <div class="trade-list">
          <div class="trade-item" v-for="(item, index) in list" :key="index">
            <div class="trade-card">
              <div class="trade-card-info">
                <div style="display: flex; justify-content: space-between">
                  <div class="card-bigTitle">
                    {{ item.note }}
                  </div>
                  <div>
                    <span class="card-info-key">{{ dateFormatFn(item.create_time) }}</span>
                  </div>
                </div>
                <div style="padding: 12px 0">
                  <span class="card-info-key">价格</span>
                  <span class="card-info-val card-info-val_highlight">{{ Math.floor(item.amount * 100) / 100 || 0 }} USDT</span>
                </div>
                <div style="display: flex; justify-content: space-between">
                  <div>
                    <span class="card-info-key">算力</span>
                    <span class="card-info-val">{{ item.power }}</span>
                  </div>
                  <div>
                    <!-- <span class="card-info-key">状态</span> -->
                    <!--  :class="[item.status == 1 ? 'green' : item.status == 2 ? 'red' : '']" -->
                    <span class="card-info-val">{{ item.status == 0 ? '待确认' : item.status == 1 ? '成功' : item.status == 2 ? '失败' : '未知' }}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="trade-buy" @click="buy(item)">购买</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="bare" v-else>
        <span>暂无数据</span>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { homeApi } from '@/api/index';
import { dateFormat, typemat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      list: [],
      power: 0,
    };
  },
  mounted() {
    this.getAccountInfo();
    this.serversList();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },
    typeFormatFn(type) {
      return typemat(type);
    },
    getAccountInfo() {
      homeApi.getUserInfo({}).then(res => {
        if (res.code == 200) {
          this.power = Math.floor(res.data.power * 100) / 100;
        }
      });
    },
    serversList() {
      homeApi.serversList({}).then(res => {
        if (res.code == 200) {
          this.list = res.data;
          // if (this.list.length > 0) {
          //   this.list.forEach(i => {
          //     this.totalPower += i.power;
          //   });
          // }
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.head {
  position: relative;
  display: flex;
  justify-content: center;
  color: #000;
  background: #fff;
  padding: 20px 0 10px;
  .arrow_img {
    position: absolute;
    left: 20px;
    top: 46%;
    width: 10px;
    height: 16px;
    // transform: translate(0, -50%);
  }
}
.assets {
  padding: 20px;
  .total_box {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #fff;
    background: #238be8;
    border-radius: 12px;
    padding: 20px;
    .total {
      font-size: 26px;
      font-weight: bold;
      margin-top: 14px;
    }
  }
  .caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #238be8;
    font-size: 14px;
    font-weight: bold;
    border-left: 4px solid #238be8;
    padding-left: 6px;
    margin: 20px 0 10px;
    .right_arrow {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0;
    .li_left {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: rgba(51, 51, 51, 1);
      .time {
        color: rgba(102, 102, 102, 1);
        margin-top: 4px;
      }
    }
    .li_right {
      font-size: 16px;
      color: #000;
    }
  }
  .bare {
    text-align: center;
    font-size: 14px;
    padding: 60px 14px;
    color: #888;
  }
}

.trade-list {
  // padding: 20px 0 0;

  .trade-item {
    padding: 16px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 0 0.25rem rgba(77, 146, 223, 0.16);
    margin-bottom: 12px;
    // box-shadow: inset 0px 0px 12px rgba(249, 67, 255, 0.3);

    .trade-buy {
      background: #238be8;
      border-radius: 4px;
      width: 60px;
      height: 30px;
      color: #fff;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      padding: 0px 6rpx;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .grey {
      background: #29303d;
      color: #78869e;
    }

    .trade-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //   padding-bottom: 16px;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.05);

      .trade-icon {
        width: 64px;
        height: 64px;
      }

      .trade-card-info {
        line-height: 18px;
        padding: 0 12px;
        flex: 1;

        .card-bigTitle {
          font-weight: 500;
          font-size: 16px;
          //   color: #fff;
        }

        .card-info-key {
          color: #999;
          margin-right: 5px;
        }
        .card-info-val {
          color: #999;
        }
        .card-info-val_del {
          text-decoration: line-through;
          margin-right: 6px;
        }
        .card-info-val_highlight {
          color: #999;
          margin-right: 6px;
        }
        .card-info-key,
        .card-info-val {
          font-weight: 400;
          font-size: 12px;
        }

        .green {
          color: #5ac725;
        }
        .red {
          color: #f56c6c;
        }
      }
    }

    .trade-info {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;

      .trade-info-item {
        width: 33.333333%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .trade-info-key {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #999;
        }
        .trade-info-val {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #000;
        }
      }
    }
  }
}
</style>