export const dateFormat = (date, fmt) => {
    //格式化时间
    var o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
    };
    fmt = fmt || "yyyy-MM-dd";
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
            );
        }
    }
    return fmt;
};

export const typemat = (type) => {
    let result
    switch (type) {
        case 101:
            result = "租赁算力"
            break;
        case 102:
            result = "直推奖励"
            break;
        case 103:
            result = "算力奖励"
            break;
        case 104:
            result = "平级奖励"
            break;
        case 105:
            result = "全球分红"
            break;
        case 106:
            result = "团队分红"
            break;
        case 107:
            result = "团队算力奖励"
            break;
        default:
            result = "未知"
    }
    return result
};
export const statusFormat = (status) => {
    let result
    switch (status) {
        case 0:
            result = "待处理"
            break;
        case 1:
            result = "已抢单"
            break;
        case 2:
            result = "成功"
            break;
        case 3:
            result = "失败"
            break;
        default:
            result = "未知"
    }
    return result
};


export const addressFormat = (address) => {
    address = address + ""
    let result = address.substr(0, 3) + "****" + address.substr(address.length - 3, 3);

    return result
};