<template>
  <div class="superior">
    <div class="fds">
      <img src="@/assets/logo.jpg" class="fds_img" />
    </div>
    <div v-if="parantId">
      <div class="address_box">
        <span>上级地址:</span>
        <span style="margin-left: 30px">{{ address }}</span>
      </div>
      <!-- <div class="address_box">
        <span>上级等级:</span>
        <span style="margin-left: 30px">{{ level || "无" }}</span>
      </div> -->
    </div>
    <div v-else class="bare">
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    level: {
      default: undefined,
    },
    address: {
      default: undefined,
    },
    parantId: {
      default: undefined,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.superior {
  font-size: 14px;
  color: #333;
  padding: 20px;
  .fds {
    display: flex;
    justify-content: center;
    margin-top: -50px;
    .fds_img {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .address_box {
    display: flex;
    // flex-wrap: wrap;
    word-break: break-all;
    margin-top: 20px;
  }
}
.bare {
  text-align: center;
  font-size: 14px;
  padding: 14px;
  color: #888;
}
</style>