<template>
  <div>
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>USDT提现</span>
    </div>
    <div class="withdraw">
      <div class="extract_box">
        <div class="caption">
          <span>收款地址</span>
          <!-- <input type="text" v-model="address" class="addressInput" placeholder="请输入收款地址" /> -->
          <span class="addressInput">{{ address }}</span>
        </div>
        <div class="field_box">
          <span>提现金额</span>
          <div class="input-box">
            <span style="font-weight: bold; padding-bottom: 4px"></span>
            <input type="number" class="input" v-model="amount" :placeholder="`请输入提现金额 （手续费${dataInfo.withdraw_fee_rate * 100 || 0}%）`" ref="input" />
          </div>
        </div>
        <div class="bottom">
          <span>最低提现金额：{{ dataInfo.withdraw_min_amount + ' USDT' }}</span>
        </div>
        <div class="bottom">
          <span>提现手续费：{{ dataInfo.withdraw_fee_rate * 100 + '%' }}</span>
        </div>
        <div class="bottom">
          <span>实际到账：{{ amount - amount * dataInfo.withdraw_fee_rate + ' USDT' }}</span>
        </div>
        <!-- <div class="bottom">
          <span>可提现余额：{{ dataInfo.usdt }}</span>
          <span class="text" @click="amount = dataInfo.usdt">全部提现</span>
        </div> -->
      </div>
      <div class="button">
        <van-button class="submit_button" type="primary" @click="withdrawCash">2小时到账，确认提现</van-button>
      </div>
    </div>
    <!-- 加载弹窗 -->
    <van-popup v-model="isApprove" round style="width: 60%" :close-on-click-overlay="false">
      <div class="bind_box">
        <div style="font-weight: bold"><van-loading type="spinner" /></div>
        <div class="address_field">处理中...</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
import { Notify } from 'vant';
import Web3 from 'web3';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      amount: '', //提现金额
      balance: 0, // 余额
      address: '',
      dataInfo: {},
      isApprove: false,
    };
  },
  computed: {
    ...mapState(['account']),
  },
  mounted() {
    this.$refs.input.focus();
    this.withdrawInfo();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    withdrawInfo() {
      homeApi.withdrawInfo().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.dataInfo = res.data;
          this.address = this.account.substring(0, 5) + '****' + this.account.substring(this.account.length - 5, this.account.length);
        }
      });
    },
    // actionGet() {
    //   homeApi.assets().then(res => {
    //     if (res.code == 200) {
    //       let obj = res.data.coin_list.filter(i => {
    //         return i.coin_key == 'usdt';
    //       });
    //       console.log(obj);
    //       this.balance = obj[0].amount;
    //     }
    //   });
    // },
    withdrawCash() {
      // if (!this.address) {
      //   this.$toast('请输入收款地址');
      //   return;
      // }
      if (!this.amount) {
        this.$toast('请输入金额');
        return;
      }
      if (this.amount > this.dataInfo.usdt * 1) {
        this.$toast('余额不足');
        return;
      }
      if (this.amount < this.dataInfo.withdraw_min_amount * 1) {
        this.$toast(`最低提现 ${this.dataInfo.withdraw_min_amount}USDT`);
        return;
      }
      this.isApprove = true;
      let web3 = new Web3(window.web3.currentProvider);
      web3.eth.personal
        .sign(web3.utils.fromUtf8('withdraw'), this.account, (err, res) => {
          let params = {
            amount: this.amount,
            sign: res,
          };
          homeApi
            .withdrawCash(params)
            .then(res => {
              this.isApprove = false;
              if (res.code == 200) {
                this.amount = '';
                Notify({ type: 'primary', message: res.msg });
              } else {
                Notify({ message: res.msg });
              }
            })
            .catch(err => {
              this.isApprove = false;
            });
        })
        .catch(err => {
          this.isApprove = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(#000, 0.8);
  letter-spacing: 1.5px;
  font-weight: 550;
  padding: 14px 0;
  top: 0;
  width: 100%;
  background: #fff;
  .arrow_img {
    position: absolute;
    left: 20px;
    width: 10px;
    height: 16px;
  }
}
.withdraw {
  min-height: 100vh;
  padding: 66px 16px 0;

  .extract_box {
    font-size: 12px;
    color: rgb(110, 110, 110);
    padding: 16px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    .caption {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      .address {
        color: #000;
      }
      .addressInput {
        text-align: right;
        color: #000;
        // border: none;
        // background-color: rgba(255, 255, 255, 0.3);
      }
    }
    .field_box {
      font-size: 14px;
      border-bottom: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
      padding: 20px 0;
      margin: 20px 0 16px;
      .field {
        flex: 1;
        font-size: 16px;
      }
      .input-box {
        display: flex;
        align-items: flex-end;
        color: rgba(#000, 0.8);
        padding: 16px 0 0;
        .input {
          border: none;
          background: none;
          font-size: 20px;
          font-weight: bold;
          padding-left: 2px;
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      padding-bottom: 4px;
      .text {
        color: rgba(48, 70, 212, 1);
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    .submit_button {
      color: rgba(#fff, 0.95);
      background: #2f46d4;
      border: 0;
      border-radius: 12px;
      padding: 12px 0;
      width: 100%;
      margin-top: 30px;
      letter-spacing: 1.5px;
    }
  }
}
input::-webkit-input-placeholder {
  /* 使用webkit内核的浏览器 */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  font-weight: normal;
}
input:-moz-placeholder {
  /* Firefox版本4-18 */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
input::-moz-placeholder {
  /* Firefox版本19+ */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
input:-ms-input-placeholder {
  /* IE浏览器 */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
.bind_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  background-color: #000;
  .address_field {
    padding-top: 16px;
  }
}
</style>