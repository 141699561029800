<template>
  <div>
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>算力租赁</span>
      <span class="right_text" @click="toRecord">租赁记录</span>
    </div>
    <div class="lease">
      <div class="balance_box">
        <div class="caption">
          <span :class="ustdType == 1 ? 'text_active' : ''" @click="ustdEvent(1)">钱包余额</span>
          <span class="system_text" :class="ustdType == 2 ? 'text_active' : ''" @click="ustdEvent(2)">系统余额</span>
        </div>
        <!-- 钱包余额 -->
        <div class="balance" v-show="ustdType == 1">
          <span>{{ Math.floor(walletBalance * 100) / 100 || 0 }}</span>
        </div>
        <!-- 系统余额 -->
        <div class="balance" v-show="ustdType == 2">
          <span>{{ system_money || 0 }}</span>
        </div>
        <div class="universal">
          <div class="li">
            <div>
              <span>质押</span>
              <span class="hint">(最小{{ dataObj.min_usdt || 0 }}USDT起)</span>
            </div>
            <!-- <span class="li_amount">{{ amount || 0 }} USDT</span> -->
          </div>
          <div class="slider">
            <!-- <van-slider v-model="amount" :max="max" :min="400" bar-height="8" active-color="#238be8" button-size="16" /> -->
            <input type="number" v-model="amount" :max="max" :min="min" placeholder="请输入质押金额" />
            <span class="danwei">USDT</span>
          </div>
          <div class="li">
            <span>可租赁算力</span>
            <!-- 钱包余额可租赁算力 -->
            <span class="li_amount" v-if="ustdType == 1">{{ amount * Number(dataObj.price) || 0 }}</span>
            <!-- 系统余额可租赁算力 -->
            <span class="li_amount" v-if="ustdType == 2">{{ amount * Number(dataObj.price) || 0 }}</span>
          </div>
        </div>
      </div>
      <div class="button" v-if="ustdType == 1">
        <!-- <van-button class="submit_button" type="primary" v-if="showApprove" @click="approve" loading-text="加载中..." :loading="isApprove">授权</van-button>
        <van-button class="submit_button" type="primary" v-else @click="submit" loading-text="加载中..." :loading="isApprove">提交</van-button> -->
        <van-button class="submit_button" type="primary" v-if="showApprove" @click="approve">授权</van-button>
        <van-button class="submit_button" type="primary" v-else @click="submit">提交</van-button>
      </div>
      <div class="button" v-else>
        <van-button class="submit_button" type="primary" @click="buy">提交</van-button>
      </div>
    </div>
    <!-- 加载弹窗 -->
    <van-popup v-model="isApprove" round style="width: 80%" :close-on-click-overlay="false">
      <div class="bind_box">
        <div style="font-weight: bold"><van-loading type="spinner" /></div>
        <div class="address_field">
          处理中...
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
import { Notify } from 'vant';
import packApi from '@/methods/pack.js';
import usdt from '@/methods/usdt.js';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import { formatAmount, parseAmount, accMul, gasProcessing } from '@/utils/format.js'; //有空看看这里 这个地方封装了bignumer处理 好
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  data() {
    return {
      amount: null, //质押
      show: true,
      contract: '0x7534f6771A9e470212ad5fA4c1f5f3a9D3Edcb07', //合约地址
      payToken: undefined, //支付币种
      walletBalance: 0, //钱包余额
      showApprove: false, //授权
      isApprove: false, //是否有授权

      ustdType: 1, // 1:链上钱包余额 2:系统余额
      dataObj: {
        price: 0,
        min_usdt: undefined,
      },
      max: 0,
      system_money: 0, //系统余额
      min: 0,
      allowanceV2: 0, // 授权额度
    };
  },
  created() {
    this.init();
  },
  watch: {
    account() {
      this.init();
    },
    amount(e) {
      if (String(e).indexOf('.') != -1) {
        this.amount = Math.trunc(e);
      }
      // if (/[^1-9]/g.test(e)) {
      //   return false;
      // }
    },
  },
  computed: {
    ...mapState(['account']),
  },
  methods: {
    init() {
      this.getPayToken(); //获取支付币种
      this.prepareRental();
    },
    getPayToken() {
      packApi.getPayToken(this.account).then(res => {
        this.payToken = res;
        console.log(res, 'aaaaaaaa');
        this.handleApproveBtnShow(); //获取授权余额
        this.getWalletBalance(); //获取钱包余额
      });
    },
    async getWalletBalance() {
      const erc20Contract = getErc20Contract(this.payToken);
      let bal = await erc20Contract.methods.balanceOf(this.account).call();
      this.walletBalance = formatAmount(bal, 18); //这个地方拿到的值是 strng类型  你绑定滚动条要用number  你看着处理下那个报错就没了

      this.max = this.walletBalance * 1;
      // this.amount = Math.trunc(this.walletBalance * 1);
      // if (this.walletBalance * 1 < 400) {
      //   this.inputDisabled = true;
      // }
    },
    async handleApproveBtnShow() {
      console.log('走了这里' + this.payToken);
      const erc20ContractV2 = getErc20Contract(this.payToken);
      const toV2 = this.contract;
      this.allowanceV2 = formatAmount(await erc20ContractV2.methods.allowance(this.account, toV2).call(), 18);
      console.log(this.allowanceV2, '授权所剩余额');
      if (this.allowanceV2 == 0) {
        this.showApprove = true;
      } else {
        this.showApprove = false;
      }
    },
    //走余额支付
    buy() {
      if (this.amount < this.min) {
        this.$toast(`算力租赁最少需要${this.min}USDT`);
        return;
      }
      // let web3 = new Web3(window.web3.currentProvider);
      // web3.eth.personal.sign(web3.utils.fromUtf8('buy lease'), this.account, (err, res) => {
      let data = {
        type: this.ustdType,
        fee: this.amount,
      };
      homeApi.rental(data).then(res => {
        if (res.code == 200) {
          Notify({ type: 'primary', message: '租赁成功' });
          this.prepareRental();
        } else {
          Notify({ type: 'danger', message: res.msg });
        }
      });
      // });
    },
    //假设转账 链上
    // test() {
    //   //注意  转账必须先走授权，只有当showapprove 是false 才走，不然必须先走一次授权 approve方法
    //   packApi.pledge(this.account, this.amount).then(res => {
    //     let data = {
    //       tx_hash: res,
    //       id: this.id, //产品ID
    //     };
    //     //开始走逻辑，发送接口给yoki
    //   });
    // },
    async submit() {
      //注意  转账必须先走授权，只有当showapprove 是false 才走，不然必须先走一次授权 approve方法
      if (this.amount < this.min) {
        this.$toast(`算力租赁最少需要${this.min}USDT`);
        return;
      }
      if (Number(this.amount) > Number(this.walletBalance)) {
        this.$toast(`余额不足`);
        return;
      }
      if (Number(this.amount) > this.allowanceV2) {
        this.$toast(`授权额度不足`);
        // 调授权
        this.isApprove = true;
        usdt
          .approve(this.account, this.contract, this.amount, this.payToken)
          .then(res => {            
            packApi
              .pledge(this.account, accMul(this.amount, Math.pow(10, 18)))
              .then(res => {
                let data = {
                  type: this.ustdType,
                  fee: this.amount,
                  txHash: res,
                };
                //开始走逻辑，发送接口给yoki
                homeApi
                  .rental(data)
                  .then(res => {
                    this.isApprove = false;
                    if (res.code == 200) {
                      this.init();
                      this.amount = '';
                      Notify({ type: 'primary', message: '租赁成功' });
                    } else {
                      Notify({ type: 'danger', message: res.msg });
                    }
                  })
                  .catch(err => {
                    this.isApprove = false;
                  });
              })
              .catch(err => {
                console.log(err);
                this.$toast(this.$t('lang.swap599'));
                this.isApprove = false;
              });
          })
          .catch(err => {
            this.$toast(this.$t('lang.swap599'));
            this.isApprove = false;
            return;
          });
      } else {
        this.isApprove = true;
        packApi
          .pledge(this.account, accMul(this.amount, Math.pow(10, 18)))
          .then(res => {
            let data = {
              type: this.ustdType,
              fee: this.amount,
              txHash: res,
            };
            //开始走逻辑，发送接口给yoki
            homeApi
              .rental(data)
              .then(res => {
                this.isApprove = false;
                if (res.code == 200) {
                  this.init();
                  this.amount = '';
                  Notify({ type: 'primary', message: '租赁成功' });
                } else {
                  Notify({ type: 'danger', message: res.msg });
                }
              })
              .catch(err => {
                this.isApprove = false;
              });
          })
          .catch(err => {
            console.log(err);
            this.$toast(this.$t('lang.swap599'));
            this.isApprove = false;
          });
      }
    },
    //授权    链上购买按钮有两个状态  一个是授权  一个是支付  用状态 showApprove来判断
    async approve() {
      if (this.amount < this.min) {
        this.$toast(`算力租赁最少需要${this.min}USDT`);
        return;
      }
      if (Number(this.amount) > Number(this.walletBalance)) {
        this.$toast(`余额不足`);
        return;
      }
      this.isApprove = true;
      // 授权
      usdt
        .approve(this.account, this.contract, this.amount, this.payToken)
        .then(res => {
          // console.log(res, 'res');
          this.showApprove = false;
          // this.isApprove = false;
          // 授权之后直接转账
          packApi
            .pledge(this.account, accMul(this.amount, Math.pow(10, 18)))
            .then(res => {
              let data = {
                type: this.ustdType,
                fee: this.amount,
                txHash: res,
              };
              //开始走逻辑，发送接口给yoki
              homeApi
                .rental(data)
                .then(res => {
                  this.isApprove = false;
                  if (res.code == 200) {
                    this.init();
                    Notify({ type: 'primary', message: '租赁成功' });
                  } else {
                    Notify({ type: 'danger', message: res.msg });
                  }
                })
                .catch(err => {
                  this.isApprove = false;
                });
            })
            .catch(err => {
              console.log(err);
              this.$toast(this.$t('lang.swap599'));
              this.isApprove = false;
            });
        })
        .catch(err => {
          console.log(err, 'err');
          this.isApprove = false;
          this.$toast(this.$t('lang.swap599'));
        });
    },
    //返回上一页
    back() {
      this.$router.back();
    },

    ustdEvent(e) {
      this.ustdType = e;

      this.amount = null;
      if (e == 1) {
        this.max = this.walletBalance * 1;
        // this.amount * 1 = Math.trunc(this.walletBalance * 1);
        // if (this.walletBalance * 1 < 400) {
        //   this.inputDisabled = true;
        // } else {
        //   this.inputDisabled = false;
        // }
      } else {
        this.max = this.system_money * 1;
        // if (this.system_money * 1 < 400) {
        //   this.inputDisabled = true;
        // } else {
        //   this.inputDisabled = false;
        // }
        // this.amount = Math.trunc(this.system_money * 1);
      }
    },

    prepareRental() {
      homeApi.prepareRental().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.dataObj = res.data;
          this.system_money = Math.floor(res.data.usdt * 100) / 100;
          this.min = res.data.min_usdt;
        }
      });
    },
    toRecord() {
      this.$router.push({ name: 'leaseRecord' });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  position: relative;
  display: flex;
  justify-content: center;
  color: #000;
  background: #fff;
  padding: 20px 0 10px;
  .arrow_img {
    position: absolute;
    left: 20px;
    top: 46%;
    width: 10px;
    height: 16px;
    // transform: translate(0, -50%);
  }
  .right_text {
    position: absolute;
    right: 20px;
  }
}
.lease {
  padding: 20px;
  .balance_box {
    background: #fff;
    border-radius: 10px;
    padding: 10px 20px 30px;
    .caption {
      display: flex;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
      .system_text {
        margin-left: 60px;
      }
      .text_active {
        color: rgba(35, 139, 232, 1);
      }
    }
    .balance {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 12px 0 16px;
    }
    .universal {
      display: flex;
      // align-items: center;
      flex-direction: column;
      // justify-content: space-between;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
      margin-top: 12px;
      .li {
        display: flex;
        flex-direction: column;
        // align-items: center;
        .li_amount {
          // font-weight: bold;
          color: #000;
          margin-top: 8px;
        }
      }
    }
    .slider {
      margin: 10px 0 18px;
      display: flex;
      align-items: center;
      input {
        border: 0;
        background-color: #f1f1f1;
        width: 100%;
        padding: 10px 10px;
        border-radius: 6px;
        font-size: 14px;
        color: #000;
      }
      .danwei {
        font-size: 13px;
        padding-left: 6px;
      }
    }
    .hint {
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      padding-left: 6px;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    .submit_button {
      height: auto;
      background: #238be8;
      border: 0;
      border-radius: 8px;
      padding: 10px 100px;
      margin-top: 44px;
    }
  }
}
.bind_box{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  .address_field{
    padding-top: 16px;
  }
}
</style>