<template>
  <div class="bar">
    <div class="mobile_phone">
      <div class="navigation_list" v-for="(item, index) of navigationList" :key="index" @click="languageSwitching(item.name)">
        <img :src="routeName == item.name ? item.bright : item.img" class="li_img" :class="routeName == item.name ? 'bright' : ''" />
        <span>{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { usApi, homeApi } from '@/api/index';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      showPopover: false,
      language: '',
      price: 0,
      menuList: [],
      navigationList: [
        { img: require('@/assets/images/tabbar/home.png'), bright: require('@/assets/images/tabbar/home-active.png'), text: '首页', name: 'Index' },
        { img: require('@/assets/images/tabbar/trade.png'), bright: require('@/assets/images/tabbar/trade-active.png'), text: '购买', name: 'trade' },
        { img: require('@/assets/images/tabbar/assets.png'), bright: require('@/assets/images/tabbar/assets-active.png'), text: '资产', name: 'assets' },
        { img: require('@/assets/images/tabbar/mine.png'), bright: require('@/assets/images/tabbar/mine-active.png'), text: '我的', name: 'mine' },
      ],
      routeName: '0',
      invite_link: '',
    };
  },
  async created() {
    this.language = await localStorage.getItem('lang');
    // this.getUserInfo();
    // this.routeName = this.$route.name
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.name == 'Swap' || val.name == 'LiquidityIndex' || val.name == 'Liquidity' || val.name == 'importMining' || val.name == 'Remove') {
          this.routeName = 'Swap';
        } else if (val.name == 'consult' || val.name == 'consultDetail') {
          this.routeName = 'consult';
        } else {
          this.routeName = val.name;
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
    account() {
      // this.getUserInfo();
    },
  },
  methods: {
    Navigation(i, router) {
      if (router != '') {
        this.$router.push({ name: router });
      } else {
        this.close(i);
      }
    },

    close(i) {
      this.menuList[i].showPopover = !this.menuList[i].showPopover;
    },
    languageSwitching(router) {
      if (this.routeName == router) return;
      if (router != '') {
        this.$router.push({ name: router });
      } else {
        this.$toast(this.$t('lang.swap109'));
      }
    },
    getPath() {},
    //我的信息
    getUserInfo() {
      if (this.account) {
        this.invite_link = '';
        homeApi.getUserInfo(this.account).then(res => {
          if (res.code == 200) {
            // this.userinfo = res.data;
            // this.parent_id = res.data.parent_id;
            if (res.data.parent_id != 0) {
              this.invite_link = res.data.invite_link;
            }
          } else {
            this.$toast(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
// .mobile_phone {
//   display: none;
//   z-index: 999;
// }
// @media screen and (max-width: 900px) {
//   .BottomNavigation {
//     display: flex;
//     padding: 0 10px;
//     .Navigation_left {
//       font-size: 18px;
//     }
//     .menu_list {
//       flex: 1;

//       margin: 0;
//       .settings {
//         // flex: 1;
//         // justify-content: space-between;
//         margin: 0;
//         .language_switching {
//           font-size: 12px;
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 700px) {

.bar {
  background: #f3f9fe;
}
.mobile_phone {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  width: calc(100% - 32px);
  height: 50px;
  background: #ffffff;
  padding: 4px 0;
  border-radius: 30px;
  margin: 16px;
  // border: 1px solid #04152d;
  // box-shadow: 0 4px 16px 0 rgba(53, 73, 93, 0.3);
  box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
  .navigation_list {
    flex: 1 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    span {
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 600;
      color: #000;
      transform: scale(0.9, 0.9);
    }
    .li_img {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
    }
    .bright {
      width: 40px;
      height: 40px;
      margin-top: -15px;
      margin-bottom: 0px;
    }
  }
}
// }
</style>