<template>
  <div class="invite">
    <div class="bg"></div>
    <div class="head">
      <img @click="back" src="@/assets/images/person/back.png" class="arrow_img" />
      <span>我的团队</span>
    </div>

    <div class="content">
      <div class="card">
        <div class="flex">
          <div class="item">
            <span>{{ datainfo.team_power || 0 }}</span>
            <span class="text">团队业绩</span>
          </div>
          <div class="item">
            <span>{{ datainfo.team_num || 0 }}</span>
            <span class="text">团队人数</span>
          </div>
          <div class="item">
            <span>{{ datainfo.direct_num || 0 }}</span>
            <span class="text">直推人数</span>
          </div>
          <!-- <div class="item">
          <span>{{ datainfo.partition_bonus || 0 }}</span>
          <span class="text">保险池</span>
        </div> -->
        </div>
        <div class="yesterday">
          <div class="yesterday_title">
            <span>昨日收益</span>
          </div>
          <div class="flex">
            <div class="item">
              <span>{{ yesterday_profit.power || 0 }}</span>
              <span class="text">算力</span>
            </div>
            <div class="item">
              <span>{{ yesterday_profit.smh || 0 }}</span>
              <span class="text">SMH</span>
            </div>
            <div class="item">
              <span>{{ yesterday_profit.team_power || 0 }}</span>
              <span class="text">业绩</span>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <span>直推列表</span>
      </div>
      <div class="table">
        <div class="th flex">
          <span class="text"> 地址（人数） </span>
          <span class="text"> 个人/团队业绩 </span>
          <span class="text"> 时间 </span>
        </div>
        <div class="tbody">
          <template v-if="list.length > 0">
            <div class="flex item" v-for="(item, index) in list" :key="index">
              <span class="text"> {{ item.address + '（' + (item.team_num || 0) + '）' }} </span>
              <span class="text"> {{ item.user_power + ' / ' + item.team_power }}</span>
              <span class="text"> {{ dateFormatFn(item.create_time) }} </span>
            </div>
          </template>
          <template v-else>
            <div class="nodata item">
              <span> 暂无数据 </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { homeApi } from '@/api/index';
import { dateFormat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      list: [],
      datainfo: {
        direct_num: 0,
        team_num: 0,
        team_power: 0,
      },
      yesterday_profit: {
        power: 0,
        smh: 0,
        team_power: 0,
      },
    };
  },
  mounted() {
    this.teamInfo();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },
    teamInfo() {
      homeApi.teamInfo().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.datainfo = res.data;
          this.list = res.data.list;
          this.yesterday_profit = res.data.yesterday_profit;
        }
      });
    },
  },
};
</script>
    
<style lang="less" scoped>
.invite {
  min-height: 100vh;
  padding-top: 50px;
  .bg {
    height: 300px;
    width: 100%;
    position: absolute;
    top: 0;
    background: url('~@/assets/images/person/bg.png') no-repeat;
    background-size: 100% 100%;
    z-index: 0;
  }
  .head {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    letter-spacing: 1.5px;
    font-weight: 550;
    padding: 14px 0;
    top: 0;
    width: 100%;
    z-index: 2;
    .arrow_img {
      position: absolute;
      left: 20px;
      width: 10px;
      height: 16px;
    }
  }
  .content {
    position: relative;
    padding: 16px;
  }
  .card {
    width: 100%;
    padding: 20px 16px;
    // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/a56ec545484b90fd350193e274990e66) 100% no-repeat;
    // background-size: 100% 100%;
    justify-content: flex-center;
    box-shadow: 0 0 20px 4px rgba(#fff, 0.66) inset;
    border-radius: 16px;
    background-color: rgba(#fff, 0.26);
    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      .text {
        font-size: 12px;
        color: rgba(206, 215, 251, 1);
        padding-top: 6px;
      }
    }
    .yesterday {
      padding: 16px 0 0;
      border-top: 1px solid rgba(255, 255, 255, 1);
      margin-top: 20px;
      &_title {
        padding-bottom: 10px;
      }
    }
  }
  .title {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 1px;
    padding: 16px 0;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table {
    box-shadow: 0px 0px 15px 6px rgba(180, 212, 212, 0.59);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 16px;
    color: #000;
    font-size: 13px;
    padding: 16px;

    .text {
      display: block;
      width: 34%;
      text-align: center;
    }
    .text:first-child {
      display: block;
      width: 42%;
      text-align: center;
    }
    .th {
      font-weight: 550;
      color: rgba(#000, 0.86);
      padding-bottom: 16px;
    }
    .tbody {
      font-size: 12px;
      color: rgba(83, 89, 122, 1);

      .item {
        padding: 10px 0;
        border-top: 1px solid rgba(232, 240, 247, 1);
      }

      .nodata {
        text-align: center;
        line-height: 380%;
      }
    }
  }
}
</style>