<template>
  <div class="house">
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>SMH管理</span>
    </div>
    <div class="tab">
      <div class="tab_item" :class="tabIndex == index ? 'tab_active' : ''" v-for="(item, index) in tabList" :key="index" @click="changeTab(index)">
        <span>{{ item }}</span>
      </div>
    </div>
    <smh-exchange v-if="tabIndex == 0"></smh-exchange>
    <smh-withdraw v-else></smh-withdraw>

    <!-- 加载弹窗 -->
    <van-popup v-model="isApprove" round style="width: 80%" :close-on-click-overlay="false">
      <div class="bind_box">
        <div style="font-weight: bold"><van-loading type="spinner" /></div>
        <div class="address_field">处理中...</div>
      </div>
    </van-popup>
  </div>
</template>
    
<script>
import { mapState } from 'vuex';
import SmhExchange from './smhExchange.vue';
import SmhWithdraw from './smhWithdraw.vue';
export default {
  components: {
    SmhExchange,
    SmhWithdraw,
  },
  data() {
    return {
      token: null, //余额
      show: true,
      address: '', //地址
      payToken: undefined, //支付币种
      walletBalance: 0, //钱包余额
      showApprove: false, //授权
      isApprove: false, //是否有授权

      tabList: ['smh兑换', 'smh提现'],
      // tabList2: ['钱包余额', '系统余额', '报单算力余额'],
      tabIndex: 0,
    };
  },
  created() {},
  watch: {
    account() {},
    amount(e) {},
  },
  computed: {
    ...mapState(['account']),
  },
  methods: {
    changeTab(e) {
      this.tabIndex = e;
    },
    //返回上一页
    back() {
      this.$router.back();
    },
  },
};
</script>
    
<style lang="less" scoped>
.house {
  min-height: calc(100vh - 80px);
}
.head {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(#000, 0.8);
  letter-spacing: 1.5px;
  font-weight: 550;
  padding: 14px 0;
  top: 0;
  width: 100%;
  background: #fff;
  .arrow_img {
    position: absolute;
    left: 20px;
    width: 10px;
    height: 16px;
  }
}
.tab {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 80px 16px 32px;
  background-color: #fff;
  border-radius: 40px;
  padding: 4px;
  box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
  &_item {
    text-align: center;
    color: #000;
    width: 50%;
  }
  &_active {
    color: #fff;
    font-weight: 700;
    padding: 10px 0;
    background-color: #4159d5;
    border-radius: 40px;
  }
  &_item1 {
    text-align: center;
    color: #000;
    width: 33%;
  }
  &_active1 {
    color: #fff;
    font-weight: 700;
    padding: 10px 0;
    background-color: #4159d5;
    border-radius: 40px;
  }
}
</style>