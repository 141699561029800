<template>
  <div>
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>申请分区</span>
    </div>
    <div class="withdraw">
      <div class="extract_box">
        <div class="caption">
          <span>您的称呼</span>
          <input type="text" v-model="nick_name" class="addressInput" placeholder="请输入您的称呼" />
        </div>
        <div class="caption">
          <span>您的SESO ID</span>
          <input type="text" v-model="seso_id" class="addressInput" placeholder="请输入您的SESO ID" />
        </div>
      </div>
      <div class="button">
        <van-button class="submit_button" type="primary" @click="partitionApply">提交申请</van-button>
      </div>
    </div>
    <!-- 加载弹窗 -->
    <van-popup v-model="isApprove" round style="width: 60%" :close-on-click-overlay="false">
      <div class="bind_box">
        <div style="font-weight: bold"><van-loading type="spinner" /></div>
        <div class="address_field">处理中...</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
import { Notify } from 'vant';
import Web3 from 'web3';
export default {
  data() {
    return {
      nick_name: '',
      seso_id: '',
      isApprove: false,
    };
  },
  mounted() {},
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    partitionApply() {
      if (!this.nick_name) {
        this.$toast('请输入您的昵称');
        return;
      }
      if (!this.seso_id) {
        this.$toast('请输入您的SESO');
        return;
      }
      this.isApprove = true;
      let params = {
        nick_name: this.nick_name,
        seso_id: this.seso_id,
      };
      homeApi
        .partitionApply(params)
        .then(res => {
          this.isApprove = false;
          if (res.code == 200) {
            this.seso_id = '';
            this.nick_name = '';
            this.$toast(res.msg);
          }
        })
        .catch(err => {
          this.isApprove = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(#000, 0.8);
  letter-spacing: 1.5px;
  font-weight: 550;
  padding: 14px 0;
  top: 0;
  width: 100%;
  background: #fff;
  .arrow_img {
    position: absolute;
    left: 20px;
    width: 10px;
    height: 16px;
  }
}
.withdraw {
  min-height: 100vh;
  padding: 66px 16px 0;

  .extract_box {
    font-size: 12px;
    color: rgb(53, 53, 53);
    padding: 16px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 16px;

    .caption {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #eeeeee;
      .address {
        color: #000;
      }
      .addressInput {
        text-align: right;
        color: #000;
        border: none;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    .caption:last-child {
      border: none;
    }
    .field_box {
      font-size: 14px;
      border-bottom: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
      padding: 20px 0;
      margin: 20px 0 16px;
      .field {
        flex: 1;
        font-size: 16px;
      }
      .input-box {
        display: flex;
        align-items: flex-end;
        color: rgba(#000, 0.8);
        padding: 16px 0 0;
        .input {
          border: none;
          background: none;
          font-size: 20px;
          font-weight: bold;

          padding-left: 2px;
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      .text {
        color: rgba(48, 70, 212, 1);
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    .submit_button {
      color: rgba(#fff, 0.95);
      background: #2f46d4;
      border: 0;
      border-radius: 12px;
      padding: 12px 0;
      width: 100%;
      margin-top: 30px;
      letter-spacing: 1.5px;
    }
  }
}
input::-webkit-input-placeholder {
  /* 使用webkit内核的浏览器 */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  font-weight: normal;
}
input:-moz-placeholder {
  /* Firefox版本4-18 */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
input::-moz-placeholder {
  /* Firefox版本19+ */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
input:-ms-input-placeholder {
  /* IE浏览器 */
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}
.bind_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  background-color: #000;
  .address_field {
    padding-top: 16px;
  }
}
</style>